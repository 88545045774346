<template>
  <div>
    <div id="surveyCreator"></div>
    <v-dialog v-model="dialog" width="500">
      <v-card>
        <v-card-title color="error">
          {{ $t('eform.discard') }}
        </v-card-title>

        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn outlined @click="dialog = false"> No </v-btn>
          <v-btn color="primary" @click="discardForm"> Yes </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import * as SurveyCreator from 'survey-creator-knockout'
import * as SurveyKo from 'survey-core'
import * as widgets from 'surveyjs-widgets'
import 'survey-core/defaultV2.min.css'
import 'survey-creator-core/survey-creator-core.min.css'
import { init as customWidget } from './customwidget'

widgets.select2(SurveyKo)
widgets.inputmask(SurveyKo)
widgets.jquerybarrating(SurveyKo)
widgets.jqueryuidatepicker(SurveyKo)
widgets.nouislider(SurveyKo)
widgets.select2tagbox(SurveyKo)
widgets.sortablejs(SurveyKo)
widgets.ckeditor(SurveyKo)
widgets.autocomplete(SurveyKo)
widgets.bootstrapslider(SurveyKo)
customWidget(SurveyKo)

SurveyKo.Serializer.addProperty('question', 'tag:number')
SurveyKo.Serializer.getProperties('boolean', 'title').visible = false
SurveyCreator.localization.getLocale('en').ed.designer = 'Form Designer'
SurveyCreator.localization.getLocale('en').ed.testSurvey = 'Test Form'

const creatorOptions = {
  showEmbeddedSurveyTab: false,
  showTestSurveyTab: true,
  showOptions: false,
  showLogicTab: false,
  showJSONEditorTab: false,
  questionTypes: [
    'text',
    'checkbox',
    'radiogroup',
    'dropdown',
    'comment',
    'rating',
    'imagepicker',
    'boolean',
    'html',
    'expression',
  ],
  showTitlesInExpressions: true,
  allowEditExpressionsInTextEditor: false,
  showSurveyTitle: false,
}

export default {
  name: 'survey-creator-new',
  props: {
    isEdit: { type: Boolean, default: false },
  },
  data() {
    return {
      dialog: false,
    }
  },
  mounted() {
    const creator = new SurveyCreator.SurveyCreator(creatorOptions)
    if (this.isEdit) {
      const dataForm = this.$store.getters['link/getSaveDataEform']
      creator.text = JSON.stringify(dataForm.survey)
    }
    // creator.saveSurveyFunc = (saveNo, callback) => {
    //   callback(saveNo, true)

    // }

    creator.toolbox.forceCompact = false
    const self = this
    creator.toolbarItems.push(
      new SurveyKo.Action({
        id: 'save',
        visible: true,
        title: 'Save',
        action: function () {
          self.$emit('save-survey', JSON.parse(creator.text))
        },
      }),
    )
    creator.toolbarItems.push(
      new SurveyKo.Action({
        id: 'discard-survey',
        visible: true,
        title: 'Discard Form',
        action: function () {
          self.dialog = true
        },
      }),
    )

    SurveyCreator.SurveyQuestionEditorDefinition.definition = {}
    creator.render('surveyCreator')
  },
  methods: {
    discardForm() {
      this.$router.push({ name: 'e-form' }, () => {})
    },
  },
}
</script>
<style>
#surveyCreator {
  height: 100vh;
}
.svc-creator .svc-full-container .svc-creator__banner {
  display: none !important;
}
</style>
